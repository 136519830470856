<template>
  <b-card
    no-body
  >
    <div class="card-header">
      <b-card-title>
        {{ title }}
      </b-card-title>
      <feather-icon
        class="float-right cursor-pointer"
        size="21"
        icon="XIcon"
        @click="$emit('closeForm')"
      />
    </div>

    <template>
      <b-card-body>
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col md="6">
                <b-row>
                  <!-- Group -->
                  <b-col cols="12">
                    <b-form-group>
                      <label>Group</label>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Group"
                      >
                        <v-select
                          v-model="userCopy.group_id"
                          label="name"
                          :state="errors.length > 0 ? false:null"
                          :options="groups"
                          :reduce="group => group.id"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!--  Username -->
                  <b-col cols="12">
                    <b-form-group>
                      <label>Username</label>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Username"
                      >
                        <b-form-input
                          v-model="userCopy.username"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Username"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!--  Password -->
                  <b-col cols="12">
                    <b-form-group>
                      <label>Password</label>
                      <validation-provider
                        #default="{ errors }"
                        rules="required|password"
                        name="Password"
                        vid="Password"
                      >
                        <b-form-input
                          v-model="userCopy.password"
                          :state="errors.length > 0 ? false:null"
                          type="password"
                          placeholder="Password"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!--  Confirm Password -->
                  <b-col cols="12">
                    <b-form-group>
                      <label>Confirm Password</label>
                      <validation-provider
                        #default="{ errors }"
                        rules="required|confirmed:Password"
                        name="ConfirmPassword"
                      >
                        <b-form-input
                          v-model="userCopy.confirm_password"
                          :state="errors.length > 0 ? false:null"
                          type="password"
                          placeholder="Confirm Password"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!--  Is Active -->
                  <b-col cols="12">
                    <b-form-group>
                      <b-form-checkbox
                        v-model="userCopy.is_active"
                        class="mt-2"
                        name="IsActive"
                        switch
                        inline
                      >
                        Is Active
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>

                  <!-- submit button -->
                  <b-col cols="12">
                    <b-button
                      variant="primary"
                      type="submit"
                      class="mr-1"
                      @click.prevent="validationForm"
                    >
                      Save
                    </b-button>
                    <b-button
                      type="reset"
                      variant="outline-secondary"
                    >
                      Reset
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-body>
    </template>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BCardTitle, BCardBody, BFormInput, BFormCheckbox, BFormGroup, BForm, BRow, BCol, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  required,
} from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardTitle,
    BCardBody,
    BFormInput,
    BFormCheckbox,
    vSelect,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
  },
  props: {
    action: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      title: '',
      required,
    }
  },
  computed: {
    ...mapState('user', {
      user: 'user',
    }),
    ...mapState('group', {
      groups: 'groups',
    }),
    userCopy() {
      return { ...this.user }
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$emit('actionSave', this.userCopy)
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
