<template>
  <div>
    <div
      v-if="isLoadingComplete"
      class="d-flex align-items-center"
    >
      <strong>Loading...</strong>
      <b-spinner class="ml-auto" />
    </div>
    <table-user
      v-show="!showForm"
      :users="tableData"
      @addUser="addUser($event)"
      @editUser="editUser($event)"
      @deleteUser="confirmDeleteUser($event)"
    />
    <form-user
      v-show="showForm"
      :action="action"
      @closeForm="closeForm"
      @actionSave="actionSave"
    />
  </div>
</template>

<script>

import { BSpinner } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { mapActions, mapMutations, mapState } from 'vuex'

import TableUser from '@/components/catalogs/user/TableUser.vue'
import FormUser from '@/components/catalogs/user/FormUser.vue'

export default {
  components: {
    BSpinner,
    TableUser,
    FormUser,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      showForm: false,
      action: '',
      searchTerm: '',
    }
  },

  computed: {
    ...mapState('user', ['users', 'isLoading']),
    tableData() {
      return this.users
    },
    isLoadingComplete() {
      return this.isLoading
    },
  },

  mounted() {
    this.searchUser()
    this.searchGroup()
  },

  methods: {
    ...mapActions('user',
      ['searchUser', 'createUser', 'deleteUser', 'updateUser', 'restoreUser']),
    ...mapActions('group',
      ['searchGroup']),
    ...mapMutations('user',
      { setUser: 'setUser' }),
    addUser() {
      this.showForm = true
      this.restoreUser()
      this.action = 'Create'
    },
    editUser(user) {
      this.setUser(user)
      this.showForm = true
      this.action = 'Edit'
    },
    closeForm() {
      this.showForm = false
    },
    actionSave(user) {
      if (this.action === 'Edit') {
        this.updateUser(user)
      }
      if (this.action === 'Create') {
        this.createUser(user)
      }
      this.showForm = false
    },
    async confirmDeleteUser(user) {
      const response = await this.$swal({
        title: `Are you sure to delete the user ${user.username}.?`,
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn Are you sure',
        },
      })
      if (response && response.value) {
        this.deleteUser(user)
      }
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
