var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"card-header"},[_c('b-card-title',[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('feather-icon',{staticClass:"float-right cursor-pointer",attrs:{"size":"21","icon":"XIcon"},on:{"click":function($event){return _vm.$emit('closeForm')}}})],1),[_c('b-card-body',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Group")]),_c('validation-provider',{attrs:{"rules":"required","name":"Group"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","state":errors.length > 0 ? false:null,"options":_vm.groups,"reduce":function (group) { return group.id; }},model:{value:(_vm.userCopy.group_id),callback:function ($$v) {_vm.$set(_vm.userCopy, "group_id", $$v)},expression:"userCopy.group_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Username")]),_c('validation-provider',{attrs:{"rules":"required","name":"Username"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Username"},model:{value:(_vm.userCopy.username),callback:function ($$v) {_vm.$set(_vm.userCopy, "username", $$v)},expression:"userCopy.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Password")]),_c('validation-provider',{attrs:{"rules":"required|password","name":"Password","vid":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"password","placeholder":"Password"},model:{value:(_vm.userCopy.password),callback:function ($$v) {_vm.$set(_vm.userCopy, "password", $$v)},expression:"userCopy.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Confirm Password")]),_c('validation-provider',{attrs:{"rules":"required|confirmed:Password","name":"ConfirmPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"password","placeholder":"Confirm Password"},model:{value:(_vm.userCopy.confirm_password),callback:function ($$v) {_vm.$set(_vm.userCopy, "confirm_password", $$v)},expression:"userCopy.confirm_password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('b-form-checkbox',{staticClass:"mt-2",attrs:{"name":"IsActive","switch":"","inline":""},model:{value:(_vm.userCopy.is_active),callback:function ($$v) {_vm.$set(_vm.userCopy, "is_active", $$v)},expression:"userCopy.is_active"}},[_vm._v(" Is Active ")])],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" Save ")]),_c('b-button',{attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" Reset ")])],1)],1)],1)],1)],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }