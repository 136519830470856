<template>
  <b-card-code title="Users">
    <!-- search input -->
    <div class="float-right">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="ml-5"
        variant="primary"
        @click="$emit('addUser')"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />
        <span class="align-middle">Add new</span>
      </b-button>
    </div>
    <table-search @changeSearchTerm="changeSearchTerm" />

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Active -->
        <span v-if="props.column.field === 'is_active'">
          <b-form-checkbox
            v-model="props.row.is_active"
            class="custom-control-primary"
            disabled
          />
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <table-actions
            @actionEdit="$emit('editUser', props.row)"
            @actionDelete="$emit('deleteUser', props.row)"
          />
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <table-pagination
          :props="props"
          :page-length="pageLength"
        />
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>

import Ripple from 'vue-ripple-directive'

import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BButton, BFormCheckbox } from 'bootstrap-vue'

import { VueGoodTable } from 'vue-good-table'
import TableSearch from '@/components/shared/TableSearch.vue'
import TableActions from '@/components/shared/TableActions.vue'
import TablePagination from '@/components/shared/TablePagination.vue'

export default {
  components: {
    BCardCode,
    BButton,
    BFormCheckbox,
    VueGoodTable,
    TableSearch,
    TableActions,
    TablePagination,
  },
  directives: {
    Ripple,
  },
  props: {
    users: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      pageLength: 50,
      dir: false,
      columns: [
        {
          label: 'ID',
          field: 'id',
        },
        {
          label: 'Username',
          field: 'username',
        },
        {
          label: 'Is Active',
          field: 'is_active',
        },
        {
          label: 'Created At',
          field: 'created_at',
        },
        {
          label: 'Updated At',
          field: 'updated_at',
        },
        {
          label: 'Actions',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  watch: {
    users(newVal) {
      this.rows = newVal
    },
  },
  methods: {
    changeSearchTerm(searchTerm) {
      this.searchTerm = searchTerm
    },
  },
}
</script>
